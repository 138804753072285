.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  width: 100%;
  background: linear-gradient(
    to right,
    #ffffff,
    #f7f7f7,
    #f0f0f0,
    #e8e8e8,
    #d1d1d3,
    #cccdd0,
    #c7c8ce,
    #c1c4cb,
    #bbc0c8
  );
  z-index: 99;

}

.navbar__logo {
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex: 0.75;
  margin: 0 1vw;
}

.navbar__logo img {
  height: 35px;
  margin: 10px;
}

.navbar__btns {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 0 1vw; */
}

.navbar__links {
  display: flex;
  flex: 3;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 0 2rem;
}

.navbar__links p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  margin-left: 2vw;
  text-decoration: none;
  padding: 2.3rem 0;
}

.navbar__links a {
  display: flex;
  align-items: center;
}

.navbar__search {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1vw;
  flex-wrap: nowrap;
}

.navbar__search input {
  font-family: var(--font-family);
  font-weight: 400;
  padding: 0.5rem 0.5rem;
  margin: 0.5rem 1rem;
  width: 270px;
  outline: none;
  background: none;
  border: 1px solid #000;
  border-radius: 4px;
}

.navbar__search input::placeholder {
  color: #000;
}

.navbar__btns button {
  display: flex;
  justify-content: center;
  flex: 1;
  height: 60px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  align-items: center;
  line-height: 28px;
  background: #2080e2;
  border: 2px solid var(--gradient-text);
  padding: 1rem 2rem;
  color: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 8px;
}

.navbar__btns button:hover {
  background: #fff;
  color: #1a6ec6;
  outline: 1px solid #1a6ec6;
}

.d-btn {
  font-family: var(--font-family);
  font-weight: 400;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  line-height: 28px;
}

.d {
  font-family: var(--font-family);
  float: right;
  overflow: hidden;
  border: none;
  outline: none;
  color: #000;
  background: inherit;
}

.d-content {
  display: none;
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 10;
}
.d:hover .d-content {
  display: flex;
  width: 100%;
  background: #061223;
  flex-direction: column;
}
.eng-services {
  position: relative;
  display: none;
  width: 100%;
  left: 0;
  z-index: 10;
}
#inner-service:hover .eng-services {
  display: flex;
  color: #fff;
  width: 100%;
  font-size: 10px;
  background: #061223;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #061223;
}

.column {
  float: left;
  padding: 20px;
  margin-left: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
}
.column a {
  display: block;
  float: none;
  color: #fff;
  text-align: left;
  padding: 5px;
  margin: 5px;
  font-size: 18px;
  font-weight: 300;
}

.column img {
  width: 200px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

.column a:hover {
  color: #fff;
  background: linear-gradient(90deg, #1a6ec6 0%, #061223 100%);
  border-radius: 5px;
}
.row:after {
  content: "";
  display: table;
  clear: both;
}

.navbar__search-menu {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar__search-menu input {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  padding: 0.5rem 1rem;
  width: 300px;
  text-decoration: none;
  border: none;
  outline: none;
  background: none;
}

.navbar__search-menu input:hover {
  outline: 1px solid #fff;
  border-radius: 5px;
}

.navbar__search-menu input::placeholder {
  color: #fff;
}

.navbar__search-menu button {
  background: none;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
}

/*Mobile view screen*/

.navbar-menu {
  display: none;
}

.navbar-menu_container {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 84px;
  position: fixed;
  left: 0;
  width: 100vw;
  background: #061223;
  color: #fff;
  padding: 0;
  margin: 0;
  overflow: scroll;
}

.navbar-menu_container-links {
  overflow: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  list-style: none;
}

.navbar-menu_container-links ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style: none;
}

.navbar-menu_container li {
  text-align: right;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
}

.navbar-menu_container-links a {
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 1400px) {
  .navbar__search {
    display: none;
  }
  .navbar__btns {
    display: none;
  }

  hr {
    display: none;
  }
}

@media screen and (max-width: 1230px) {
  .column a {
    font-size: 17px;
  }
  
  .column img {
    width: 160px;
  }
}
@media screen and (max-width: 1085px) {
  .navbar__links {
    display: none;
  }
  .navbar__search {
    display: none;
  }
  .navbar__btns {
    display: none;
  }

  .navbar-menu {
    display: flex;
    position: relative;
    margin-left: 1rem;
  }

  .navbar {
    padding: 0.75rem 2rem;
  }

  .navbar hr {
    display: none;
  }

  .navbar-links_container {
    display: none;
  }

  .navbar__menu-search {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .navbar {
    padding: 0.75rem 1rem;
  }

  .navbar-menu_container {
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 0;
    overflow: auto;
    transition: opacity 1000ms ease;
  }

  .navbar-menu_container-links {
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0 1rem;
  }

  .navbar__menu-search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 1rem;
    border-bottom: 1px solid #616161;
  }

  .navbar__menu-search form {
    width: 100%;
  }

  .navbar__menu-search input {
    width: 100%;
    padding: 12px 36px;
    font-size: 17px;
    background: var(--color-footer);
    border: 0;
    color: #fff;
    border-radius: 8px;
    outline: none;
  }

  .navbar-menu_container-links ul {
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0;
    text-align: right;
    width: 90vw;
  }

  .navbar-menu_container-links li {
    padding: 1rem;
    text-align: right;
    list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    border-bottom: 1px solid #616161;
    width: 100%;
  }

  .navbar-menu_container-links a {
    width: 100%;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    transition: all 700ms ease;
  }
}

.slide-in-top {
  -webkit-animation: slide-in-top 1s both;
  animation: slide-in-top 1s both;
}

.slide-in-top-2 {
  -webkit-animation: slide-in-top 0.3s both;
  animation: slide-in-top 0.3s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-1-12 16:58:39
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-top-2 {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top-2 {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}



.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #061223;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  /* padding: 12px 16px; */
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}
