.blog-container_article {
  width: 270px;
  display: flex;
  flex-direction: column;
  background: var(--color-footer);
  border-radius: 1vh;
  cursor: pointer;
}

.blog-container_article-image {
  width: 100%;
  height: 180px;
  background: var(--color-bg);
  border-radius: 1vh;
}

.blog-container_article-image img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  border-radius: 1vh 1vh 0 0;
}

.blog-container_article-content {
  display: flex;
  flex-direction: column;
  /* justify-content: space-evenly; */
  justify-content: flex-start;
  padding: 2rem 1.2rem;
  height: 160px;
}

.blog-container_article-content p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: white;
  letter-spacing: 0.1rem;
  margin-top: 1.4rem;
}

.blog-container_article-content h4 {
 font-family: var(--font-family);
  font-size: 17px;
  font-weight: 600;
  line-height: 30px;

  color: #fff;
  cursor: pointer;
}

.blog-container_article-content p:last-child {
  cursor: pointer;
}

@media screen and (max-width: 1424px) {
  .blog-container_article {
    width: 270px;
  }
}

@media screen and (max-width: 726px) {
  .blog-container_article-content {
    padding: 1rem 1.2rem;
    height: 180px;
  }
}
@media screen and (max-width: 450px) {
  .blog-container_article {
    width: 400px;
  }
  .blog-container_article-content h3 {
    font-size: 18px;
    line-height: 25px;
  }
  .blog-container_article-content h4 {
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
  }
  .blog-container_article-content p {
   font-weight: 300;
   font-size: 11px;
   line-height: 15px;
   color: white;
   margin-top: .6rem;
 }
}
