.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  background: url(../../assets/footer-bg.webp);
  background-size: cover;
  background-position: center;
}

.footer-heading p {
  font-size: 1rem;
  font-family: var(--font-family);
  line-height: 1.5rem;
  color: #000;
}

.footer-links {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: wrap;

  width: 100%;
  text-align: left;
}

.footer-links__logo {
  display: flex;
  flex-direction: column;
}

.footer-links__logo img {
  height: 50px;

  margin-bottom: 1rem;
}

.footer-links__logo h1 {
  font-family: var(--font-family);
  font-size: 1.8rem;
  color: #000;
  font-weight: 400;
  line-height: 2.5rem;
  margin-left: 1rem;
}

.footer-links_div {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.footer-links_div h4 {
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.8rem;
  font-family: var(--font-family);
  color: var(--color-footer);
  text-align: left;

  margin-bottom: 0.9rem;
}

.footer-links_div p {
  font-size: 15px;
  line-height: 18px;
  font-family: var(--font-family);
  color: #000;

  margin: 0.4rem 0;
}

.footer-links_icon {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.footer-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footer-links_social_media {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.footer-links_social_media svg {
  position: center;
  margin-top: 4px;
}

.footer-copyright {
  margin-top: 1.5rem;
  text-align: center;
  width: 100%;
}

.footer-copyright p {
  font-size: 0.6rem;
  font-family: var(--font-family);
  line-height: 15px;
  color: #000;
}

.circular-icon {
  background: #3b82ca;
  color: #fff;
  text-align: center;
  align-self: center;
  width: 49.5px;
  height: 49.5px;
  border-radius: 50%;
  padding: 0.3rem;
  outline: none;
  border: none;
}

.circular-icon:hover {
  background: #102643;
  color: #fff;
}

.footer-links_container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 60rem;
}

@media screen and (max-width: 1412px) {
  .footer-links_container {
    width: 50rem;
  }
}

@media screen and (max-width: 1258px) {
  .footer-links_container {
    width: 40rem;
  }
}

@media screen and (max-width: 1089px) {
  .footer-links_container {
    width: 35rem;
  }
}

@media screen and (max-width: 1009px) {
  .footer-links_container {
    width: 30rem;
  }
}

@media screen and (max-width: 929px) {
  .footer-links_container {
    width: 40rem;
  }
  .footer-links__logo {
    margin: 0 0 2rem 0;
  }
}

@media screen and (max-width: 850px) {
  .footer-heading h1 {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
}

@media screen and (max-width: 555px) {
  .footer-heading h1 {
    font-size: 1.5rem;
    line-height: 1.7rem;
  }

  .footer-heading p {
    font-size: 1rem;
    line-height: 1.2rem;
  }

  .footer-links_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .footer-links_icon {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin: 0 -1rem;
  }

  .footer-links_icon svg {
    display: none;
  }
  .footer-logo {
    width: 200px;
  }
  .circular-icon {
    width: 39.5px;
    height: 39.5px;
  }
  .footer-links_social_media svg {
    position: center;
    margin: 0;
    width: 20px;
  }
}

@media screen and (max-width: 400px) {
  .footer-heading h1 {
    font-size: 1.3rem;
    line-height: 1.5rem;
  }

  .footer-heading p {
    font-size: 0.9rem;
    line-height: 1.1rem;
  }

  .footer-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

@media screen and (max-width: 390px) {
  .footer-links__logo img {
    width: 100%;
    height: auto;
  }
}
