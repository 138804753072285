.culture {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--color-background);
}

.culture p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--color-text);
  letter-spacing: 1px;
  margin-top: 1.5rem;
  text-align: justify;
}

.culture h1 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 49px;
  line-height: 75px;
  letter-spacing: -0.04em;
  text-align: center;
  margin-bottom: 1.5rem;
}
.culture__container_left {
  display: flex;
  gap: 35px;
  flex-direction: row;
  margin: 3rem 0;
}
.culture__container_right {
  display: flex;
  gap: 35px;
  flex-direction: row-reverse;
  margin: 3rem 0;
}
.culture__content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.culture__image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.culture__image img {
  width: 100%;
  box-shadow: 5px 2px;
  border-radius: 15px;
  height: auto;
  margin: 0 3rem;
}
.culture h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.04em;
  color: rgb(163, 155, 112);
  margin-top: 1.5rem;
}

@media screen and (max-width: 1200px) {
  .culture__image img {
    height: auto;
    margin: 0;
  }

  .culture__content {
    margin: 1rem 0;
  }

  .culture__image {
    margin: 1rem 0;
  }

  .culture__container_left {
    flex-direction: column;
    margin: 1rem 0;
  }
  .culture__container_right {
    flex-direction: column;
    margin: 1rem 0;
  }
  .culture__content p {
    text-align: left;
  }
}
@media screen and (max-width: 858px) {

 
}
@media screen and (max-width: 768px) {
  .culture h1 {
    font-size: 40px;
    line-height: 50px;
  }

  .culture h2 {
    font-size: 24px;
    line-height: 32px;
  }

  .culture p {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
  }
  .culture__container_left {
    flex-direction: column;
    margin: 0;
  }
  .culture__container_right {
    flex-direction: column;
    margin: 0;
  }
  .culture__content {
    margin: 1rem 0;
  }
  .culture__image {
    margin: 1rem 0;
  }
  .culture__content p {
    text-align: left;
  }
}
