.industries-carousel__center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.industries h1 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 49px;
  line-height: 75px;
  letter-spacing: -0.04em;
  text-align: center;
  margin-bottom: 1.5rem;
}

.industries-carousel__list {
  align-items: center;
  display: grid;
  grid-row-gap: 1.25rem;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  overflow: hidden;
  list-style-type: none;
  color: var(--color-text);
  border-radius: 1vh;
  padding: 1.5rem;
}

.industries-carousel__item {
  grid-column: 1;
  grid-row: 1;
  line-height: 1.2;
  transition: transform 1s;
}
.industries-carousel__item.active {
  transform: translateX(0);
  gap: 1rem;
}
.industries-carousel__item.left {
  transform: translateX(-110%);
}
.industries-carousel__item.right {
  transform: translateX(110%);
}

.industries-carousel__indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.industries-carousel__dot {
  background-color: white;
  border-radius: 50%;
  border: 0.0625rem solid #696a6b;
  display: block;
  height: 0.5rem;
  width: 0.5rem;
}
.industries-carousel__dot.active {
  background-color: #696a6b;
}

.industries-carousel__controls {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.industries-carousel__controls button {
  cursor: pointer;
  font-size: 1.5rem;
  background-color: var(--color-text);
  padding: 0.5rem;
  border-radius: 50%;
  border: none;
  outline: none;
}

.industries-carousel__image img {
  border-radius: 15px;
  box-shadow: 5px 2px #285069;
  height: auto;
  width: 100%;
  /* width: 500px; */
  border: 1px solid var(--color-text);
}

.industries-carousel__image {
  /* width: 70%; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.industries-carousel {
  display: flex;
  gap: 30px;
  justify-content: space-between;
}

.industries-carousel__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  gap: 1rem;
}

.industries-carousel__content h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 25px;
  line-height: 40px;
  letter-spacing: -0.04em;
  color: var(--color-text);
  text-align: center;
  padding: 0.5rem;
}

.industries-carousel__content p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: var(--color-text);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  text-align: justify;
  /* width: 550px; */
}

.industries__container_left {
  display: flex;
  gap: 15px;
  flex-direction: row;
  margin: 3rem 0;
}

.industries__container_right {
  display: flex;
  gap: 15px;
  flex-direction: row-reverse;
  margin: 3rem 0;
}
.industries__content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.industries__image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.industries__image img {
  width: 100%;
  box-shadow: 5px 2px ;
  border-radius: 15px;
  height: auto;
  margin: 0 3rem;
}

.industries__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--color-background);
}

.industries__container p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--color-text);
  letter-spacing: 1px;
  margin-top: 1.5rem;
  text-align: justify;
}

.industries__container h1 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 62px;
  line-height: 75px;
  letter-spacing: -0.04em;
  text-align: center;
  margin-bottom: 1.5rem;
}

.industries__container h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.04em;
  color: #e0dae0;
  text-align: center;
  margin-top: 1.5rem;
}

/* @media screen and (max-width: 1468px) {
  .industries-carousel__content p {
    padding: 1rem 2rem;
  }
  .industries-carousel__content h2 {
    font-size: 24px;
    line-height: 32px;
  }
  .industries-carousel__image img {
    height: 300px;
  }
  .industries-carousel {
    flex-direction: column;
  }
} */

@media screen and (max-width: 1024px) {
  .industries-carousel__content p {
    /* padding: 1rem 1rem; */
  }
  .industries-carousel__content h2 {
    font-size: 20px;
    line-height: 28px;
  }
  .industries-carousel__image img {
    height: auto;
    width: 100%;
  }
  .industries-carousel {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .industries-carousel__content {
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .industries__content {
    margin: 1rem 0;
  }
  .industries__image {
    margin: 1rem 0;
  }

  .industries__content h2 {
    font-size: 24px;
    line-height: 32px;
  }

  .industries__content p {
    font-size: 18px;
    line-height: 24px;
  }

  .industries__container_left,
  .industries__container_right {
    flex-direction: column;
    margin: 1rem 0;
  }
}

@media screen and (max-width: 812px) {
  .industries h1 {
    font-size: 40px;
    line-height: 50px;
  }

  .industries p {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
  }
  .industries__container_left, .industries__container_right {
    flex-direction: column;
    margin: 0;
  }
  .industries__content p {
    font-size: 16px;
    line-height: 24px;
    /* text-align:left; */
    /* text-align: left; */
  }
  .industries__content {
    margin: 1rem 0;
  }
  .industries__image {
    margin: 1rem 0;
  }
  .industries__image img {
    height: auto;
    margin: 0;
  }
  .industries-carousel__content p {
    /* padding: 1rem 1rem; */
    /* font-size: 16px; */
    line-height: 24px;
  }
  .industries-carousel__content h2 {
    /* font-size: 18px; */
    line-height: 24px;
  }
  .industries-carousel__image img {
    height: auto;
    width: 100%;
  }
  .industries-carousel {
    flex-direction: column;
  }
}

@media screen and (max-width: 600px) {
  .industries__content p {
    font-size: 13px;
    line-height: 24px;
    text-align: left;
  }
  .industries p {
    font-size: 17px;
    line-height: 24px;
    text-align: left;
  }
  .industries-carousel__content p {
    font-size: 13px;
    padding: 0;
  }
  .industries-carousel__list {
    padding: 0;
  }
  .industries__image img {
    /* width: 270px; */
    height: auto;
    margin: 0;
  }
}

@media screen and (max-width: 510px) {
  .industries__image img {
    height: auto;
    margin: 0;
  }
}