.variant1 {
  position: absolute;
  height: 251px;
  width: auto;
  left: 0;
  top: 500.34px;
  z-index: -15;
}

.variant2 {
  position: absolute;
  height: 169.09px;
  width: auto;
  right: 0;
  top: 158px;
  z-index: -15;
}

.variant4 {
  position: absolute;
  height: 259.31px;
  width: auto;
  right: 0;
  top: 1517.26px;
  z-index: -15;
}

.variant3 {
  position: absolute;
  left: 0;
  width: auto;
  top: 1907.34px;
  z-index: -15;
  transform: matrix(1, -0.06, -0.06, -1, 0, 0);
}

.variant5 {
  position: absolute;
  height: 259.31px;
  width: auto;
  top: 2505px;
  right: 0;
  z-index: -15;
}

.variant6 {
  position: absolute;
  left: 0;
  top: 2954px;
  width: auto;
  z-index: -15;
}
.variantsolution4 {
  position: absolute;
  height: 259.31px;
  right: 0;
  top: 1217.26px;
  width: auto;
  z-index: -15;
}

.variantsolution3 {
  position: absolute;
  left: 0;
  width: auto;
  height: auto;
  top: 1707.34px;
  z-index: -15;
  transform: matrix(1, -0.06, -0.06, -1, 0, 0);
}

.variant7 {
  position: absolute;
  height: 259.31px;
  width: auto;
  right: 0;
  top: 3193px;
  z-index: -15;
}

.variant8 {
  position: absolute;
  left: 0;
  width: auto;
  top: 3642px;
  z-index: -15;
}

.variant9 {
  position: absolute;
  height: 259.31px;
  width: auto;
  right: 0;
  top: 3891px;
  z-index: -15;
  transform: scaleY(-1);
}

.variant10 {
  position: absolute;
  left: 0;
  width: auto;
  top: 4340px;
  z-index: -15;
}

.variant11 {
  position: absolute;
  height: 259.31px;
  right: 0;
  width: auto;
  top: 4589px;
  z-index: -15;
}

.variant12 {
  position: absolute;
  left: 0;
  width: auto;
  top: 5038px;
  z-index: -15;
}

.variant13 {
  position: absolute;
  height: 259.31px;
  right: 0;
  width: auto;
  top: 5287px;
  z-index: -15;
}

.variant14 {
  position: absolute;
  left: 0;
  width: auto;
  top: 5736px;
  z-index: -15;
}

.variant15 {
  position: absolute;
  height: 259.31px;
  right: 0;
  width: auto;
  top: 5985px;
  z-index: -15;
}

.variant16 {
  position: absolute;
  left: 0;
  width: auto;
  top: 6434px;
  z-index: -15;
}

.variant17 {
  position: absolute;
  height: 259.31px;
  right: 0;
  width: auto;
  top: 6683px;
  z-index: -15;
}

.variant18 {
  position: absolute;
  left: 0;
  width: auto;
  top: 7132px;
  z-index: -15;
}

.variant19 {
  position: absolute;
  height: 259.31px;
  right: 0;
  width: auto;
  top: 7381px;
  z-index: -15;
}

.variant20 {
  position: absolute;
  left: 0;
  width: auto;
  top: 7830px;
  z-index: -15;
}

.variant21 {
  position: absolute;
  height: 259.31px;
  right: 0;
  width: auto;
  top: 8079px;
  z-index: -15;
}

.variant22 {
  position: absolute;
  left: 0;
  width: auto;
  top: 8528px;
  z-index: -15;
}

.variant23 {
  position: absolute;
  height: 259.31px;
  right: 0;
  width: auto;
  top: 8777px;
  z-index: -15;
}

.variant24 {
  position: absolute;
  left: 0;
  width: auto;
  top: 9226px;
  z-index: -15;
}

.line1 {
  position: absolute;
  width: 461px;
  height: 49px;
  left: -400px;
  top: 351px;
  animation-name: myAnimation;
  animation-timing-function: ease-out;
  animation-duration: 10000ms;
  animation-iteration-count: infinite;
  z-index: -15;
}

@keyframes myAnimation {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(40px);
  }
  40% {
    transform: translateX(80px);
  }
  50% {
    transform: translateX(100px);
  }
  60% {
    transform: translateX(80px);
  }
  80% {
    transform: translateX(40px);
  }
  100% {
    transform: translateX(0);
  }
}

.line2 {
  position: absolute;
  height: 49px;
  top: 2094px;
  right: 0;
  animation-name: myAnimation2;
  animation-timing-function: ease-out;
  animation-duration: 10000ms;
  animation-iteration-count: infinite;
  z-index: -15;
}

.line-static {
  position: absolute;
  height: 49px;
  top: 2094px;
  right: 0;
  z-index: -15;
}

@keyframes myAnimation2 {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(-40px);
  }
  40% {
    transform: translateX(-80px);
  }
  50% {
    transform: translateX(-80px);
  }
  60% {
    transform: translateX(-80px);
  }
  80% {
    transform: translateX(-40px);
  }
  100% {
    transform: translateX(0);
  }
}

@media screen and (max-width: 768px) {
  .line1 {
    display: none;
  }
  .line2 {
    display: none;
  }
  .line-static {
    display: none;
  }
  .variant1 {
    display: none;
  }
  .variant2 {
    display: none;
  }
  .variant3 {
    display: none;
  }
  .variant4 {
    display: none;
  }
  .variant5 {
    display: none;
  }
  .variant6 {
    display: none;
  }
  .variant7 {
    display: none;
  }
  .variant8 {
    display: none;
  }
  .variant9 {
    display: none;
  }
  .variant10 {
    display: none;
  }
  .variant11 {
    display: none;
  }
  .variant12 {
    display: none;
  }
  .variant13 {
    display: none;
  }
  .variant14 {
    display: none;
  }
  .variant15 {
    display: none;
  }
  .variant16 {
    display: none;
  }
  .variant17 {
    display: none;
  }
  .variant18 {
    display: none;
  }
  .variant19 {
    display: none;
  }
  .variant20 {
    display: none;
  }
  .variant21 {
    display: none;
  }
  .variant22 {
    display: none;
  }
  .variant23 {
    display: none;
  }
  .variant24 {
    display: none;
  }
}
