.contact-us {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-background);
}

.contact-us p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text);
  letter-spacing: 0.1rem;

  margin-top: 1.5rem;
}

.contact-us h1 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 62px;
  line-height: 75px;
  letter-spacing: -0.04em;
  text-align: center;

  margin: 1.5rem 0;
}

.contact-us__form {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  width: 40vw;
}

.contact-us__form input {
  margin: 1rem 0;
  padding: 1rem;
  border: 1px solid var(--color-text);
  border-radius: 8px;
  outline: none;
}

.contact-us__form textarea {
  margin: 1rem 0;
  padding: 1rem 1rem 5rem 1rem;
  border: 1px solid var(--color-text);
  border-radius: 8px;
  outline: none;
  font-family: var(--font-family);
}

.contact-us__form button {
  margin: 1rem 0;
  padding: 1rem;
  font-size: 1rem;
  border: 1px solid var(--color-text);
  border-radius: 8px;
  outline: none;
  background-color: var(--color-text);
  color: var(--color-background);
  cursor: pointer;
}

@media screen and (max-width: 1000px) {
  .contact-us__form {
    width: 80vw;
  }
}

@media screen and (max-width: 768px) {
  .contact-us h1 {
    font-size: 40px;
    line-height: 50px;
  }

  .contact-us p {
    font-size: 16px;
    line-height: 24px;
    margin: 0.5rem 0;
  }
}

@media screen and (max-width: 550px) {
  .contact-us__form {
    width: 80vw;
  }
}
