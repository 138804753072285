.iot {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  background: var(--secondary-background);
}

.iot-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  margin-left: 5rem;
}

.iot-content h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 2.5rem;
  text-transform: capitalize;
}

.iot-content p {
  font-size: 1.2rem;
  letter-spacing: 0.2rem;
  font-weight: 500;

  margin-top: 1.2rem;
}

.iot-content ul li {
  font-size: 1.1rem;
  margin: 0.3rem 0;
  font-weight: 200;
}

.iot-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iot-image img {
  border-radius: 15px;
  /* width: 400px; */
  width: 100%;
  height:400px;
}

@media screen and (max-width: 1050px) {
  .iot {
    flex-direction: column;
    width: 100%;
  }

  .iot-content {
    margin: 0 0 3rem;
  }
}

@media screen and (max-width: 650px) {
  .iot-content h1 {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  .iot-content p {
    line-height: 2rem;
  }

  .iot-image img {
    /* width: 400px; */
    width: 100%;
  }
}

@media screen and (max-width: 520px) {
  .iot-content h1 {
    font-size: 2rem;
    line-height: 2.5rem;
    letter-spacing: 0.1rem;
  }

  .iot-content p {
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.1rem;
  }

  .iot-image img {
    /* width: 300px; */
    width: 100%;
    height: 300px;
  }

  .iot-content ul li {
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.1rem;
  }
}
@media screen and (max-width: 300px) {
  .iot-image img {
    /* width: 250px; */
    width: 100%;
    height: 250px;
  }
}
