.careers {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.careers p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text);
  letter-spacing: 0.1rem;

  margin-top: 1.5rem;
}

.careers a {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  letter-spacing: 0.1rem;
  color: var(--color-subtext);
  display: flex;
  align-items: center;
}

.careers h1 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 62px;
  line-height: 75px;
  letter-spacing: -0.04em;
  color: var(--color-text);
  text-align: center;
}

.careers h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.04em;
  color: var(--color-text);

  margin: 1.5rem 0;
}

@media screen and (max-width: 768px) {
  .careers {
    flex-direction: column;
  }

  .careers p {
    font-size: 16px;
    line-height: 24px;
    margin: 0.5rem 0;
  }

  .careers h1 {
    font-size: 40px;
    line-height: 50px;
  }

  .careers h2 {
    font-size: 24px;
    line-height: 32px;
  }
}
