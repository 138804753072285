.company {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--color-background);
}

.company__content {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.company__content p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 23px;
  line-height: 30px;
  color: var(--color-text);
  letter-spacing: 0.1rem;
  text-align: justify;
  margin-top: 1.5rem;
}

.company__image img {
  width: 300px;
  height: auto;
}

.company h1 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 62px;
  line-height: 75px;
  letter-spacing: -0.04em;
  text-align: center;
}

.company h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.04em;
  color: var(--color-text);

  margin-top: 1.5rem;
}

@media screen and (max-width: 768px) {
  .company {
    flex-direction: column;
  }

  .company__content {
    flex: 1;
  }

  .company__image {
    display: none;
  }

  .company__content p {
    text-align: left;
    font-size: 16px;
    line-height: 24px;
    margin: 0.5rem 0;
  }

  .company__content h2 {
    font-size: 20px;
    line-height: 30px;
    margin: 0.5rem 0;
  }
}
