@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --font-family: "Poppins", sans-serif;

  --gradient-text: linear-gradient(89.97deg, #2772c0 1.84%, #67aff9 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #ae67fa -13.86%, #f49867 99.55%);

  --color-bg: #0b1930;
  --color-footer: #17416e;
  --color-blog: #042c54;
  --color-text: #b2cfeb;
  --color-subtext: #ff8a71;
}
