.solutions {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.solutions h1 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 49px;
  line-height: 80px;
  letter-spacing: -0.04em;
  text-align: center;
}

.solutions h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.04em;
  color: var(--color-text);

  margin: 1.5rem;
}

.solutions-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 5vh 0;
}

@media screen and (max-width: 700px) {
  .solutions-heading h1 {
    font-size: 46px;
    line-height: 52px;
  }
}

@media screen and (max-width: 550px) {
  .solutions-heading h1 {
    font-size: 34px;
    line-height: 42px;
  }
}
