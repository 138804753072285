* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background: #0a182f;
  font-family: "Poppins", sans-serif;
}

a {
  color: unset;
  text-decoration: none;
}

.gradient__text {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 0.1rem;
}

.section__padding {
  padding: 6rem 10rem;
}

.section__margin {
  margin: 6rem 10rem;
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.footer__padding {
  padding: 2rem 4rem;
}

.image {
  transition: all 0.5s ease;
  width: 500px;
  height: 500px;
  object-fit: cover;
  border: 3px solid #fff;
  object-position: center;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* @media screen and (max-width: 1524px) {
  .section__padding {
    padding: 2rem 10rem;
  }

  .section__margin {
    margin: 6rem 6rem;
  }
} */

@media screen and (max-width: 1280px) {
 

  .section__margin {
    margin: 6rem 5rem;
  }

  .image {
    width: 400px;
    height: 400px;
  }
}

@media screen and (max-width: 1024px) {

  .section__margin {
    margin: 6rem 4rem;
  }
}

@media screen and (max-width: 900px) {
 

  .section__margin {
    margin: 4rem 3rem;
  }

  .footer__padding {
    padding: 1rem 2rem;
  }
}

@media screen and (max-width: 768px) {
  .section__padding {
    padding: 2rem;
  }

  .section__margin {
    margin: 2rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 1rem 1rem;
  }

  .section__margin {
    margin: 1rem 1rem;
  }
  .list ul li {
    margin-left: 2rem;
    line-height: 2rem;
    font-size: 1rem;
    color: #fff;
  }
}

.subtitle {
  font-size: 1.5rem;
  font-weight: 400;
  color: #7d9ebe;
  margin-bottom: 1rem;
  text-transform: capitalize;
}

.subtitle-line {
  padding: 1rem 0;
}

.list {
  margin-left: 2rem;
  line-height: 2rem;
  font-size: 1.2rem;
  color: #fff;
}

[id] {
  scroll-margin: 150px;
}

@media screen and (max-width: 700px) {
  .image {
    width: 300px;
    height: 300px;
  }
}

@media screen and (max-width: 767px) {
  [data-aos] {
    pointer-events: auto !important;
    opacity: 1 !important;
    transform: none !important;
  }
}
