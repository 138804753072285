.hero {
  display: flex;
  justify-content: center;
  gap: 50px;
  /* flex-wrap: wrap; */
  align-items: center;
}

.hero img {
  border-radius: 10px;
  max-width: 70vh;
}

.hero-content {
  flex: 1;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
}

.hero-content h1 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 40px;
  letter-spacing: -0.04em;
}

.hero-content p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  color: var(--color-text);
  margin-top: 1.5rem;
}

.hero-btns {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 17px;
  line-height: 27px;
  background: var(--gradient-text);
  border: 2px solid var(--gradient-text);
  padding: 1.5rem 5rem;
  margin-top: 3rem;
  color: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 8px;
}

.hero-btns button:hover {
  background: #fff;
  color: #1a6ec6;
}

@media screen and (max-width: 1380px) {
  .hero-content h1 {
    font-size: 30px;
  }

  .hero-content p {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    margin-top: 1.5rem;
  }

  .hero-btns {
    padding: 1.1rem 3rem;
    margin-top: 3rem;
  }
}
@media screen and (max-width: 1300px) {
  .hero {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-direction: column-reverse;
    align-items: center;
  }
  .hero img {
    width: 100%;
  }
}
@media screen and (max-width: 1050px) {
  .hero {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;
  }
  .hero-content h1 {
    font-size: 25px;
    text-align: left !important;
  }

  .hero-content p {
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    margin-top: 1.5rem;
  }

  .hero-btns {
    padding: 1.1rem 3rem;
    margin-top: 3rem;
  }
  .hero img {
    width: 100%;
  }
  .hero-content {
    margin: 0 0 3rem;
  }
}
@media screen and (max-width: 950px) {
  .hero {
    display: flex;
    /* justify-content: center; */
    gap: 20px;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .hero img {
    border-radius: 10px;
    max-width: 100%;
  }
}
@media screen and (max-width: 650px) {
  .hero-content h1 {
    font-size: 23px;
  }

  .hero-content p {
    font-size: 16px;
  }

  .hero-content p {
    font-size: 16px;
    line-height: 24px;
  }

  .hero-content__people {
    flex-direction: column;
  }

  .hero-content__people p {
    margin: 0;
  }

  .hero-btns input,
  .hero-btns button {
    font-size: 16px;
    line-height: 24px;
  }

  .hero-gif {
    height: 200px;
  }
}

@media screen and (max-width: 490px) {
  h1 {
    font-size: 28px;
    line-height: 48px;
  }

  .hero-content p {
    line-height: 24px;
  }

  .hero-btns input,
  .hero-btns {
    padding: 1.1rem 2rem;
    margin-top: 3rem;
  }

  .hero-gif {
    height: 150px;
  }
}
