.visops-main-img {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.visops-main-img img{
 width: 60%;
 box-shadow: 5px 2px;
  border-radius: 15px;
  height: 400px;
}

.visops-img {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.visops-img img {
  width: 100%;
  box-shadow: 5px 2px;
  border-radius: 15px;
  height: auto;
}
.visops-container {
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  background-color: var(--color-background);
}

.visops-container p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--color-text);
  letter-spacing: 1px;
  margin-top: 1.5rem;
  text-align: justify;
}

.visops-container h1 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 62px;
  line-height: 75px;
  letter-spacing: -0.04em;
  text-align: center;
  margin-bottom: 1.5rem;
}

.visops-container h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.04em;
  color: #e0dae0;
  text-align: center;
  margin-top: 1.5rem;
}
.visops-container_left {
  display: flex;
  flex-direction: row;
  gap: 35px;
  margin: 2rem 0;
}

.visops-container_right {
  display: flex;
  gap: 35px;
  flex-direction: row-reverse;
  margin: 2rem 0;
}
.visops-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media screen and (max-width: 1200px) {
  .visops-container_left {
    flex-direction: column;
    margin: 1rem 0;
  }
  .visops-container_right {
    flex-direction: column;
    margin: 1rem 0;
  }
  .visops-content {
    margin: 1rem 0;
  }
  .visops-content h2 {
    font-size: 24px;
    line-height: 32px;
  }

  .visops-content p {
    font-size: 18px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1000px) {
.visops-img img {
  width: 100%;
  height: auto;
}
.visops-main-img img{
  width: 90%;
  height: auto;
}
}
@media screen and (max-width: 812px) {
  .visops-container_left {
    flex-direction: column;
    margin: 0;
  }

  .visops-container_right {
    flex-direction: column;
    margin: 0;
  }
  .visops-content p {
    font-size: 16px;
    line-height: 24px;
  }
  .visops-content {
    margin: 1rem 0;
  }
}
@media screen and (max-width: 600px) {
  .visops-content p {
    font-size: 13px;
    line-height: 24px;
    text-align: left;
  }
}
