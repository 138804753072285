.team {
  display: flex;
  flex-direction: column;
}

.team p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: var(--color-text);
  letter-spacing: 0.1rem;

  margin: 1.5rem 0;
  text-align: center;
}

.team h1 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 49px;
  line-height: 75px;
  letter-spacing: -0.04em;
  color: var(--color-text);
  text-align: center;
}

.team h2 {
  color: rgb(163, 155, 112);
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.04em;
  text-align: center;
  margin: 2rem 0;
}

.team__members {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.team__member {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1rem;
  padding: 1rem;
  background: #f5f5f5;
  width: 270px;
  height: 370px;
  border-radius: 1vh;
  text-align: center;
}

.leadership__member {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1rem;
  padding: 1rem;
  background: #f5f5f5;
  width: 270px;
  height: 370px;
  border-radius: 1vh;
  text-align: center;
}

.leadership__member h3 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.1rem;
  color: #000;
  text-align: center;
  margin: 1rem 0;
}

.leadership__member p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.1rem;
  color: #737373;
  text-align: center;
  margin: 1rem 0;
}

.leadership__member img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-bottom: 1rem;
  background-color: #f5f5f5;
}

.team__member img {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-bottom: 1rem;
  background-color: #f5f5f5;
}

.team__member h3 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.1rem;
  color: #000;
  text-align: center;
  margin: 1rem 0;
}

.team__member p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.1rem;
  color: #737373;
  text-align: center;
  margin: 1rem 0;
}

.team__member:hover {
  background: #e5e5e5;
}

@media screen and (max-width: 768px) {
  .team__members {
    flex-direction: column;
  }

  .team__member {
    width: 300px;
    margin: 1rem 0;
  }

  .team h1 {
    font-size: 40px;
    line-height: 40px;
  }

  .team h2 {
    font-size: 24px;
    line-height: 24px;
  }

  .team p {
    font-size: 16px;
    line-height: 24px;
  }
  .leadership__member {
    margin-top: 30px;
  }
}

@media screen and (max-width: 350px) {
  .leadership__member {
    width: 240px;
    margin-top: 20px;
  }
  .team__member {
    width: 240px;
  }
}
