.blogs {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.blogs p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text);
  letter-spacing: 0.1rem;

  margin-top: 1.5rem;
}

.blogs h1 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 49px;
  line-height: 80px;
  letter-spacing: -0.04em;
  text-align: center;
}

.blogs h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.04em;
  color: var(--color-text);
  text-align: center;

  margin: 1.5rem;
}

.blog-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
}

@media screen and (max-width: 700px) {
  .blog-heading h1 {
    font-size: 46px;
    line-height: 52px;
  }
}

@media screen and (max-width: 550px) {
  .blog-heading h1 {
    font-size: 34px;
    line-height: 42px;
  }
  .blogs p {
    font-weight: 400;
    font-size: 17px;
    line-height: 28px;
    margin-top: 0;
  }
}
