.solution-container_article {
  width: 280px;
  display: flex;
  flex-direction: column;
  background: var(--color-footer);
  border-radius: 1vh;
  cursor: pointer;
}

.solution-container_article-image {
  width: 100%;
  height: 180px;
  background: var(--color-bg);
  border-radius: 1vh;
}

.solution-container_article-image img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  border-radius: 1vh 1vh 0 0;
}

.solution-container_article-content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  padding: 2rem 1.2rem;
  height: 210px;
}

.solution-container_article-content p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: white;
  letter-spacing: 0.1rem;
  margin-top: 1.4rem;
}

.solution-container_article-content a {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: white;
  letter-spacing: 10px;
  text-align: center;

  margin-top: 2.2rem;
}

.solution-container_article-content h4 {
  font-family: var(--font-family);
  font-size: 17px;
  font-weight: 600;
  line-height: 30px;
  color: #fff;
  cursor: pointer;
}

.solution-container_article-content p:last-child {
  cursor: pointer;
}

@media screen and (max-width: 550px) {
  .solution-container_article-content h3 {
    font-size: 18px;
    line-height: 25p;
  }
  .solution-container_article-content h4 {
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  .solution-container_article-content p {
    font-weight: 300;
    font-size: 11px;
    line-height: 15px;
    margin-top: .6rem;
  }
}

@media screen and (max-width: 1424px) {
  .solution-container_article {
    max-width: 300px;
  }
}

.modal,
.overlay {
  width: 100vw;
  min-height: 100vh;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  z-index: 998;
}

body.active-modal {
  overflow-y: hidden;
}

.overlay {
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}
.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--color-footer);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  width: 80vw;
  min-height: 55vh;
  overflow: auto;

  z-index: 999;
}

.modal-content-image {
  width: 100%;
  height: 220px;
  background: var(--color-bg);
  border-radius: 1vh;
}

.modal-content-image img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
  cursor: pointer;
}

.modal-content-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
}

.modal-content-text h3 {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 24px;
  color: #fff;
  line-height: 20px;
  letter-spacing: 0.1rem;
  margin-top: 1.5rem;
}

.modal-content-text p {
  font-weight: 400;
  font-size: 18px;
  color: #fff;
  line-height: 20px;
  letter-spacing: 0.1rem;
  /* margin-top: 1.5rem; */
}

@media screen and (max-width: 768px) {
  .modal-content-text h3 {
    font-size: 18px;
    line-height: 25p;
  }

  .modal-content-text p {
    font-size: 12px;
    line-height: 15px;
  }

  .modal-content-text {
    height: 50vh;
  }

  .modal-content-text p:last-child {
    font-size: 12px;
    line-height: 15px;
    padding-bottom: 0.5rem;
  }
}
