/* MovingText.css */

@keyframes move-text {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}


@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.fadeInUp {
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.cost-management h4:hover{
    color: #c1c8cd;
  }

.cost__content {
  padding: 30px 130px 100px 130px;
  margin: 0 0 50px 0;
  background-color: rgb(22, 73, 100);
  /* border-radius: 5%; */
}
.hr{
  margin-top: 100px !important;  
}
.cost__content h2 {
  width: 100%;
  animation: typing 4s steps(20) forwards infinite;
  white-space: nowrap;
  overflow: hidden;
  border-right: 0.1em solid #666;  font-family: var(--font-family);
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.04em;
  color: rgb(163, 155, 112);
  text-align: center;
  margin-top: 1.5rem;
}

.cost__content h4 {
  animation: fadeInUp 4s ease;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 38px;
  letter-spacing: 1px;
  color: var(--color-text);
  text-align: justify;
  margin-top: 1.5rem;
  color:rgb(181, 213, 218);
}

.cost-management-img{
  width: 70% !important;
  margin-top: 50px !important;
}
.tab-font{
  text-align: "center";
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 0.1rem;
}
@media screen and (max-width: 1200px) {
  .industries__image {
    margin: 1rem 0;
  }
}
@media screen and (max-width: 1000px) {
  .cost__content h2 {
    font-size: 20px;
  }
  .cost__content {
    margin-top: -10px;
  }
  .cost__content h4 {
    text-align: left;
    font-size: 16px;
    line-height: 24px;
  }
  .cost__content {
    padding: 10px;
  }
  .cost-management-img{
    width: 90% !important;
    margin-top: 50px !important;
  }
}
@media screen and (max-width: 812px) {
  .industries__image {
    margin: 1rem 0;
  }
  .industries__image img {
    height: auto;
    margin: 0;
  }
}
@media screen and (max-width: 684px) {
  .cost__content h2 {
    font-size: 20px;
  }
  .cost__content h2 {
    text-align: left;
    font-size: 14px;
  }
  .cost__content h4 {
    text-align: left;
    font-size: small;
  }
  .cost-management-img{
    width: 90% !important;
    margin-top: 20px !important;
  }
  .industries__image img {
    /* width: 270px; */
    height: auto;
    margin: 0;
  }
}
@media screen and (max-width: 510px) {
  .industries__image img {
    height: auto;
    margin: 0;
  }
}
@media screen and (max-width: 482px) {
  .cost__content h2 {
    line-height: 25px;
  }
  .cost-management-img{
    width: 100% !important;
    margin-top: 20px !important;
  }
  .cost__content {
    margin-top: -10px;
  }
  .cost__content h4 {
    text-align: left;
  }
  .cost__content h2 {
    /* width: 100%; */
    animation: none;
    white-space:normal;
    overflow:auto;
  }
}
@media screen and (max-width: 400px) {
  .tab-font{
     font-size: 10px !important;
  }
}
@media screen and (max-width: 307px) {
  .tab-font{
     font-size: 9px !important;
     font-weight: bold !important;
  }
}

/* 

.services-carousel__center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.services h1 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 49px;
  line-height: 75px;
  letter-spacing: -0.04em;
  text-align: center;
  margin-bottom: 1.5rem;
}

.services-carousel__list {
  align-items: center;
  display: grid;
  grid-row-gap: 1.25rem;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  overflow: hidden;
  list-style-type: none;
  color: var(--color-text);
  border-radius: 1vh;
  padding: 1.5rem;
}

.services-carousel__item {
  grid-column: 1;
  grid-row: 1;
  line-height: 1.2;
  transition: transform 1s;
}
.services-carousel__item.active {
  transform: translateX(0);
  gap: 1rem;
}
.services-carousel__item.left {
  transform: translateX(-110%);
}
.services-carousel__item.right {
  transform: translateX(110%);
}

.services-carousel__indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.services-carousel__dot {
  background-color: white;
  border-radius: 50%;
  border: 0.0625rem solid #696a6b;
  display: block;
  height: 0.5rem;
  width: 0.5rem;
}
.services-carousel__dot.active {
  background-color: #696a6b;
}

.services-carousel__controls {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.services-carousel__controls button {
  cursor: pointer;
  font-size: 1.5rem;
  background-color: var(--color-text);
  padding: 0.5rem;
  border-radius: 50%;
  border: none;
  outline: none;
}

.services-carousel__image img {
  height: auto;
  width: 80%;
  border: 1px solid var(--color-text);
}

.services-carousel__image {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.services-carousel {
  display: flex;
  justify-content: space-between;
}

.services-carousel__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  gap: 1rem;
}

.services-carousel__content h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 25px;
  line-height: 40px;
  letter-spacing: -0.04em;
  color: var(--color-text);
  text-align: center;
  padding: 0.5rem;
}

.services-carousel__content p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: var(--color-text);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 1rem;
}

.services__container_left {
  display: flex;
  flex-direction: row;
  margin: 3rem 0;
}
.services__container_right {
  display: flex;
  flex-direction: row-reverse;
  margin: 3rem 0;
}
.services__content {
  flex: 1;
  display: flex;
  /* justify-content: center;
  align-items: center; 
  flex-direction: column;
}
.services__image {
  flex: 1;
  display: flex;
  justify-content: center; 
   align-items: center;
}
.services__image img {
  width: 500px;
  height: 300px;
  margin: 0 3rem;
}

.services__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--color-background);
}

ol li {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  color: var(--color-text);
  letter-spacing: 1px;
  margin-top: .5rem;
  margin-left: 50px;
}
#more,#more2,#more3,#more4,#more5,#more6,#more7 {
  margin-left: 300px;
  display: none;
}
.li-6{
  margin-top: -15px;
}
#myBtn,#myBtn2,#myBtn3,#myBtn4,#myBtn5,#myBtn6,#myBtn7 {
  width: 130px;
  cursor: pointer;
  background: transparent;
  border: none;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  color: rgb(194, 99, 99);
  letter-spacing: 1px;
}

.services__container h1 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 62px;
  line-height: 75px;
  letter-spacing: -0.04em;
  text-align: center;
  margin-bottom: 1.5rem;
}

.services__container h2 {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.04em;
  color: var(--color-text);
  margin-left: 40px;
  margin-top: 1.5rem;
}

/* @media screen and (max-width: 1468px) {
  .services-carousel__content p {
    padding: 1rem 2rem;
  }
  .services-carousel__content h2 {
    font-size: 24px;
    line-height: 32px;
  }
  .services-carousel__image img {
    height: 300px;
  }
  .services-carousel {
    flex-direction: column;
  }
} 

@media screen and (max-width: 1024px) {
  .services-carousel__content p {
    padding: 1rem 1rem;
  }
  .services-carousel__content h2 {
    font-size: 20px;
    line-height: 28px;
  }
  .services-carousel__image img {
    height: auto;
    width: 100%;
  }
  .services-carousel {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .services-carousel__content {
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .services__content {
    margin: 1rem 0;
  }
  .services__image {
    margin: 1rem 0;
  }

  .services__content h2 {
    font-size: 24px;
    line-height: 32px;
  }

  .services__content p {
    font-size: 18px;
    line-height: 24px;
  }

  .services__container_left {
    flex-direction: column;
    margin: 1rem 0;
  }
  .services__container_right {
    flex-direction: column;
    margin: 1rem 0;
  }
}

@media screen and (max-width: 812px) {
  .services h1 {
    font-size: 40px;
    line-height: 50px;
  }

  .services h2 {
    font-size: 24px;
    line-height: 32px;
  }

  .services p {
    font-size: 17px;
    line-height: 24px;
    text-align: left;
  }
  .services__container_left {
    flex-direction: column;
    margin: 0;
  }
  .services__content p {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
  }
  .services__container_right {
    flex-direction: column;
    margin: 0;
  }
  .services__content {
    margin: 1rem 0;
  }
  .services__image {
    margin: 1rem 0;
  }
  .services__image img {
    width: 400px;
    height: auto;
    margin: 0;
  }
  .services-carousel__content p {
    padding: 1rem 1rem;
    font-size: 15px;
    line-height: 24px;
  }
  .services-carousel__content h2 {
    font-size: 18px;
    line-height: 24px;
  }
  .services-carousel__image img {
    height: auto;
    width: 100%;
  }
  .services-carousel {
    flex-direction: column;
  }
}

@media screen and (max-width: 450px) {
  .services__image img {
    width: 300px;
  }
}
@media screen and (max-width: 332px) {
  .services__container h1 {
        font-size: 30px;
        margin-bottom: 0;
  }
  .services__container p{
    width: 100%;
  }
  .services__container h2 {
    margin-left: 10px;
  }
  ol li{
    margin-left: 20px;
    letter-spacing: 0.5;
    font-size: 12px;
  }
  #myBtn, #myBtn2, #myBtn3, #myBtn4, #myBtn5, #myBtn6, #myBtn7 {
    font-size: 12px;
  }
  .services__image img {
    width: 220px;
    height: auto;
    margin: 0;
  }
  .services__image img {
    width: 230px;
  }
  }
@media screen and (max-width: 265px) {
  .services__image img {
    width: 180px;
    height: auto;
    margin: 0;
  }
}
@media screen and (max-width: 403px) {
  .technology-tabs .gradient__text {
    font-size: 10px;
  }
  .css-cuefkz-MuiContainer-root{
    padding: 0 !important;
    margin: 0 !important;
  }
} */
