.blog-container_event {
  max-width: 250px;
  display: flex;
  flex-direction: column;
  background: var(--color-footer);
  border-radius: 1vh;
}

.blog-container_event-image {
  width: 100%;
  height: 150px;
  background: var(--color-bg);
  border-radius: 1vh;
}

.blog-container_event-image img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
  border-radius: 1vh 1vh 0 0;
}

.blog-container_event-content {
  display: flex;
  flex-direction: column;
  height: 150%;
  padding: 1rem 1.5rem;
}

.blog-container_event-content p {
  font-family: var(--font-family);
  font-weight: 500;
  line-height: 35px;
  color: #fff;
}

.blog-container_event-content h4 {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
  width: 200px;

  color: #fff;
  cursor: pointer;
}

.blog-container_event-content p:last-child {
  cursor: pointer;
}

@media screen and (max-width: 550px) {
  .blog-container_event-content h3 {
    font-size: 18px;
    line-height: 25p;
  }
}
