.accordion-header {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.accordion-header h1 {
  font-size: 3rem;
  line-height: 3.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.accordion-container {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.accordion-panel {
  flex-grow: 0.5;
  transition: all 0.3s ease;
  overflow: hidden;
  width: 5vw;
  margin: 0 0.5vw;
  padding: 0.5rem;
  border-radius: 1vw;
  background-color: #fff;
  overflow: hidden;
}

.accordion-panel.active {
  flex-grow: 10;
  width: 100%;
}

.accordion-panel.active img {
  height: 50vh;
  width: 100%;
}

.accordion-panel img {
  height: 50vh;
  width: 100%;
  border-radius: 1vw;
  object-fit: cover;
  object-position: center;
  transition: all 0.3s ease;
}

.accordion-content h3 {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 2.5rem;
  font-weight: 500;
  font-size: 1rem;
}

.accordion-content p {
  display: none;
}

.accordion-panel.active .accordion-content p {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 400;
  color: #081f3d;
}

.accordion-panel.active .accordion-content h3 {
  line-height: 2.5rem;
  font-size: 2rem;
  font-weight: 500;
  color: #081f3d;
}

.accordion-panel.active .accordion-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

@media screen and (max-width: 1256px) {
.accordion-content h3 {
  font-size: small;
}
}
@media screen and (max-width: 1032x) {
.accordion-content h3 {
  font-size: smaller;
}
}
@media screen and (max-width: 768px) {
  .accordion-header {
    flex-direction: column;
  }
 
  .accordion-header h1 {
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .accordion-panel.active img {
    height: 25vh;
    width: 100%;
  }

  .accordion-container {
    margin-top: 0;
    flex-direction: column;
  }

  .accordion-panel {
    width: 90vw;
    height: 10vh;
    flex-grow: 1;
    margin: 0.5rem 0;
    border-radius: 1vh;
  }

  .accordion-panel .accordion-content h3 {
    line-height: 2.5rem;
    font-size: 2rem;
    font-weight: 500;
    color: #081f3d;
  }

  .accordion-panel.active {
    width: 90vw;
    height: 50vh;
    flex-grow: 1;
  }

  .accordion-panel img {
    height: 100%;
    width: 100%;
    border-radius: 1vw;
    object-fit: cover;
    object-position: center;
    transition: all 0.3s ease;
  }

  .accordion-panel.active .accordion-content h3 {
    line-height: 2.5rem;
    font-size: 1.5rem;
    font-weight: 500;
    color: #081f3d;
  }

  .accordion-panel.active .accordion-content p {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 400;
    color: #081f3d;
  }
}
