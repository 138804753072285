/* MovingText.css */

@keyframes move-text {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
  }
  
  
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .about h4:hover{
      color: #c1c8cd;
    }
  
  .about__content {
    padding: 0 0 0 0;
    margin: 0 0 50px 0;
    background-color: #0b1930;;
    /* border-radius: 5%; */
  }
  
  .about__content p{
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: var(--color-text);
    letter-spacing: 0.1rem;
    margin: 1.5rem 0;
  }

  .about__content h2 {
    width: 100%;
    animation: typing 4s steps(20) forwards infinite;
    white-space: nowrap;
    overflow: hidden;
    border-right: 0.1em solid #666;  font-family: var(--font-family);
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    letter-spacing: -0.04em;
    color: rgb(163, 155, 112);
    text-align: center;
    margin-top: 1.5rem;
  }
  
  .about__content h4 {
    animation: fadeInUp 4s ease;
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 38px;
    letter-spacing: 1px;
    color: var(--color-text);
    text-align: justify;
    margin-top: 1.5rem;
    color:rgb(181, 213, 218);
  }
  
  .about-img{
    width: 70% !important;
    margin-top: 50px !important;
  }
  .tab-font{
    text-align: "center";
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 0.1rem;
  }

  @media screen and (max-width: 1000px) {
    .about__content p {
        text-align: left;
    }
    .about__content h4 {
      text-align: left;
      font-size: 16px;
      line-height: 24px;
    }
    .about__content {
      padding: 20px;
    }
    .about-img{
      width: 90% !important;
      margin-top: 50px !important;
    }
  }
  @media screen and (max-width: 812px) {
    .industries__image {
      margin: 1rem 0;
    }
    .industries__image img {
      height: auto;
      margin: 0;
    }
    .about__content p{
      letter-spacing:0;
    }
  }
  @media screen and (max-width: 684px) {
    .about__content h2 {
      font-size: 20px;
    }
    .about__content h2 {
      text-align: left;
      font-size: 30px;
    }
    .about__content h4 {
      text-align: left;
      font-size: small;
    }
    .about-img{
      width: 90% !important;
      margin-top: 20px !important;
    }
    .industries__image img {
      /* width: 270px; */
      height: auto;
      margin: 0;
    }
  }
  @media screen and (max-width: 510px) {
    .industries__image img {
      height: auto;
      margin: 0;
    }
  }
  @media screen and (max-width: 482px) {
    .about__content h2 {
      line-height: 25px;
    }
    .about-img{
      width: 100% !important;
      margin-top: 20px !important;
    }
    .about__content {
      margin-top: -10px;
    }
    .about__content h4 {
      text-align: left;
    }
    .about__content h2 {
      /* width: 100%; */
      animation: none;
      white-space:normal;
      overflow:auto;
    }
  }
  @media screen and (max-width: 400px) {
    .tab-font{
       font-size: 10px !important;
    }
  }
  @media screen and (max-width: 307px) {
    .tab-font{
       font-size: 9px !important;
       font-weight: bold !important;
    }
  }
  